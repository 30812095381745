<template>
    <div class="home">
        <div class="home-top pointer">
            <img :src="igU4YA" alt="智能医疗系统" @click="$router.push({path: '/medical', query:{index: 0}})" />
            <img :src="iwU4YA" alt="居家健康产品" @click="$router.push({path: '/medical', query:{index: 1}})" />
        </div>
        <div class="home-img pointer" :style="{background: `url(${A84_gY}) center no-repeat`}" @click="$router.push({path: '/medical', query:{index: 1}})">
            <img v-lazy="Ao4_gY" alt="智能健康马桶" />
        </div>
        <div class="home-img pointer" @click="$router.push({path: '/medical'})" :style="{background: `url(${A84_wY}) center no-repeat`}">
            <img v-lazy="go4_wY" alt="智能医疗系统" />
        </div>
        <div class="home-img pointer" :style="{background: `url(${A84qwc}) center no-repeat`}"  @click="$router.push({path: '/medical', query:{index: 3}})">
            <img v-lazy="Ao4qwc" alt="智能眼镜" />
        </div>
        <img v-lazy="A84qgI" alt="关于我们" />
        <div class="home-swiper">
             <div class="swiper-container"> 
                <div class="swiper-wrapper">
                    <div class="swiper-slide">
                        <img :src="gY4iwQ" alt="经营理念" />
                    </div>
                    <div class="swiper-slide">
                        <img :src="clAcwj" alt="企业愿景" />
                    </div>
                    <div class="swiper-slide">
                        <img :src="PzogUw" alt="加入我们" />
                    </div>
                </div>
            </div>
        </div>
        <img v-lazy="Dzi0Ag" alt="合作机构" />
        <img v-lazy="A84vwM" alt="合作机构名单" />
    </div>
</template>
<script>
import { iwU4YA, igU4YA, Ao4_gY, A84_gY, go4_wY, A84_wY, A84qwc, Ao4qwc, A84qgI, Dzi0Ag, A84vwM, gY4iwQ, clAcwj, PzogUw  } from '@/utils/imgUrl'
import Swiper from 'swiper'
import 'swiper/dist/css/swiper.css';
export default {
    name: 'home',
    data(){
        return{
            mySwiper: null
        }
    },
    computed:{
        iwU4YA: () => iwU4YA,
        igU4YA: () => igU4YA,
        Ao4_gY: () => Ao4_gY,
        A84_gY: () => A84_gY,
        go4_wY: () => go4_wY,
        A84_wY: () => A84_wY,
        A84qwc: () => A84qwc,
        Ao4qwc: () => Ao4qwc,
        A84qgI: () => A84qgI,
        Dzi0Ag: () => Dzi0Ag,
        A84vwM: () => A84vwM,
        gY4iwQ: () => gY4iwQ,
        clAcwj: () => clAcwj,
        PzogUw: () => PzogUw,
    },
    mounted(){
        this.imgSwiper()
    },
    methods:{
        imgSwiper(){
            this.mySwiper = new Swiper('.swiper-container', {
                watchSlidesProgress: true,
                loop: true,
                loopedSlides: 3,
                centeredSlides : true,
                autoplay: {
                    disbleOnInteraction: false,
                    delay: 4000
                },
                speed: 1500,
                on:{
                    progress: function( progress) {
                    for (var i = 0; i < this.slides.length; i++) {
                        var slide = this.slides.eq(i);
                        var slideProgress = this.slides[i].progress;
                        var modify = 1
                        if (Math.abs(slideProgress) > 1) {
                            modify = (Math.abs(slideProgress) - 1) * 0.4 + 1;
                        }
                        var translate = slideProgress * modify * 550 + 'px';
                        var scale = 1 - Math.abs(slideProgress) / 3;
                        var zIndex = 999 - Math.abs(Math.round(10 * slideProgress));
                        slide.transform('translateX(' + translate + ') scale(' + scale + ')');
                        slide.css('zIndex', zIndex);
                        slide.css('opacity', 1);
                        if (Math.abs(slideProgress) > 3) {
                            slide.css('opacity', 0);
                        }
                    }
                },
                setTransition: function(transition) {
                    for (var i = 0; i < this.slides.length; i++) {
                        var slide = this.slides.eq(i)
                        slide.transition(transition);
                    }
                }
                }
          })
        }
    }
}
</script>
<style lang="scss" scoped>
.swiper-container {
    padding: 40px 80px 0;
    width: 800px;
}
 
.swiper-slide {
    width: 800px;
    height: 480px;
    overflow: hidden;
    background: #fff;
    img{
        width: 100%;
        height: 100%;
    }
}
.home-swiper{
    background: #eee;
    width: 100%;
    height: 560px;
    line-height: 560px;
}
.home{
    background: #090a0c;
    img{
        display: block;
    }
}
.home-top{
    margin: auto;
    width: 1300px;
    display: flex;
    img{
        width: 50%;
    }
}
.home-img{
    width: 100%;
    display: flex;
    justify-content: center;
    img{
        width: 1300px;
    }
}
</style>